.cirtifications_card {
    margin: 2%;
    position: relative;
    width: 300px;
    border-radius: 5px;
    background-color: white;
    border: 2px solid #5E3212;
    box-shadow: 1px 1px 2px white;
}

.cirtifications_card img {
    border-radius: 5px 5px 0 0;
    height: 200px;
    width: 200px;
    border-bottom: 2px solid black;
}

.cirtifications_card .description {
    text-align: center;
    color: #5E3212;
    font-family: 'Times New Roman', Times, serif;
    font-weight: 500;
    font-size: 20px;
    padding: 4px 0;
}