.skill_card {
    border-radius: 5px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    position: relative;
    z-index: 1;
}

.skill_card .image {
    text-align: center;
    margin: auto;
    justify-content: center;
    padding: 10px;
    background-color: white;
    border-radius: 5px 5px 0 0;
}

.skill_card .description {
    padding: 2px;
    border: 2px solid gray;
    border-top: none;
    font-weight: 600;
    padding: 5px;
    text-align: center;
    border-radius: 0 0 5px 5px;
    text-shadow: 1px 1px 10px gray;
}

.all_skills {
    display: flex;
    justify-content: center;
    margin: 0 30px 0 30px;
    flex-wrap: wrap;
}