input[type="text"],
input[type="email"],
textarea,
button {
    background-color: #6d360e33;
    margin-top: 25px;
    width: 350px;
    outline: none;
    padding: 10px 10px 10px 10px;
    font-size: 18px;
    border: 1px solid black;
}

button {
    width: fit-content;
    padding: 1px;
    margin: 10px 10px 10px 0;
}

button:hover {
    background-color: rgb(8, 56, 8);
    transition: .5s ease;
    border: 1px solid green;
}

.child tr td {
    height: 60px;
}