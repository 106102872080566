body {
  margin: 0;
  background-color: #f3e8df;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media (max-width: 1200px) {
  body {
    zoom: 0.8;
  }
}

@media (max-width: 1000px) {
  body {
    zoom: 0.7;
  }
}

@media (max-width: 810px) {
  body {
    zoom: 0.5;
  }
}

@media (max-width: 600px) {
  body {
    zoom: 0.4;
  }
}

@media (max-width: 500px) {
  body {
    zoom: 0.3;
  }
}