.role,
.time {
    font-weight: 500;
    font-size: 16px;
    height: 35px;
    font-family: 'Times New Roman', Times, serif;
}

.skills_row {
    text-align: left;
    font-size: 16px;
    font-weight: 550;
    padding: 5px 0;
}

.text_scroll {
    overflow-y: scroll;
    height: 160px;
    padding: 5px 8px 5px 0;
}

.text_scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    background-color: #F5F5F5;
    margin: 5px;
}

.text_scroll::-webkit-scrollbar {
    width: 4px;
}

.text_scroll::-webkit-scrollbar-thumb {
    border-radius: 20px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #fae7d8;
    margin: 5px;
}